import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import styles from './blog.module.css';
import '../components/base.css';
import ArticlePreview from '../components/article-preview';
import Container from '../components/container';

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const posts = get(this, 'props.data.allContentfulBlogPost.edges');

    const firstPost = posts[0];
    const otherPosts = posts.slice(1);

    return (
      <div
        style={{ background: '#fff', maxWidth: '100vw', overflow: 'hidden' }}>
        <Helmet title={siteTitle}/>
        <div className={styles.hero}>
          <h1>
            Walk<span style={{ color: '#fff' }}>Up</span> Blog ✍️
          </h1>
          <p>
            From the team fighting
            restaurant queues in London ⚔️
          </p>
        </div>
        <div className="article-list-wrapper">
          <Container>
            {/*<h2 className="section-headline">Recent articles</h2>*/}
            <ArticlePreview article={firstPost.node} lessTilt/>
            <br/>
            <ul className="article-list">
              {otherPosts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node}/>
                  </li>
                );
              })}
            </ul>
          </Container>
        </div>
      </div>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
