import React from 'react';
import Link from 'gatsby-link';
import Tilt from 'react-tilt';
import { Tag } from 'antd';

import styles from './article-preview.module.css';

export default ({ article, lessTilt }) => (
  <Tilt className="Tilt"
        options={{ max: lessTilt ? 5 : 15, scale: lessTilt ? 1.02 : 1.05 }}>
    {console.log(article.description.childMarkdownRemark)}
    <Link to={`/blog/${article.slug}`}>
      <div className={styles.preview}>
        {/*<Img alt="" sizes={article.heroImage.sizes} />*/}
        <div className={styles.metaContainer}>
          <Tag color="#19a8e0">
            {article.publishDate}
          </Tag>
        </div>
        <h3 className={styles.previewTitle}>
          {article.title}
        </h3>
        <p>{article.description.childMarkdownRemark.html.replace(`<p>`, ``).replace(`</p>`, ``)}</p>
      </div>
    </Link>
  </Tilt>

)
